import {
    UserOutlined,
    CheckSquareOutlined,
    AuditOutlined,
    BookOutlined
  } from "@ant-design/icons";
  import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
  
  const dashBoardNavTree = [
    {
      key: "user",
      path: `${ADMIN_PREFIX_PATH}/user`,
      title: "User",
      icon: UserOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "role",
      path: `${ADMIN_PREFIX_PATH}/role`,
      title: "Role",
      icon: BookOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: "role",
      path: `${ADMIN_PREFIX_PATH}/role`,
      title: "Role",
      // icon: BookOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
        key: "koperasi",
        path: `${ADMIN_PREFIX_PATH}/koperasi`,
        title: "Koperasi",
        icon: AuditOutlined,
        breadcrumb: false,
        submenu: [],
      },
      
  ];
  
  const navigationRootConfig = [...dashBoardNavTree];
  
  export default navigationRootConfig;