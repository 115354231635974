import {
  DashboardOutlined,
  HomeOutlined,
  RetweetOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  DownloadOutlined,
  ReloadOutlined,
  UploadOutlined,
  DatabaseOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "angsuran",
    path: `${ADMIN_PREFIX_PATH}/rekap-transaksi`,
    title: "Rekap Transaksi",
    icon: RetweetOutlined,
    permissions: [
      "GET_DOWNLOAD_DANA_POTONGAN",
      "GET_DOWNLOAD_DANA_POTONGAN_ZAHIR",
      "GET_TRANSACTION_RECAP_COUNT",
      "GET_TRANSACTION_RECAP_NON_MEMBER",
      "GET_TRANSACTIONS",
      "GET_TRANSACTIONS_RECAP",
      "GET_USER_CAFE_TRANSACTION_RECAP",
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "riwayat-bulanan",
    path: `${ADMIN_PREFIX_PATH}/bukti-transfer`,
    title: "Upload Bukti Transfer",
    icon: UploadOutlined,
    permissions: [
      "ACCEPT_LOAN",
      "CHECK_USER_LOAN_CONFIRMATION",
      "CONFIRM_LOAN",
      "DELIVERY_TIME_CHECK",
      "GET_BAI_BARANG_LOAN",
      "GET_LOANS",
      "GET_RELEASED_TRANSCRIPT",
      "GET_BAI_BARANG_PO",
      "GET_BAI_BARANG_PO_DETAIL",
      "GET_LOANS_MONTHLY_BY_TYPE",
      "RELEASE_LOAN",
      "TRANSFER_LOAN",
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "download-csv",
    path: `${ADMIN_PREFIX_PATH}/download-csv`,
    title: "Download Rekap CSV",
    icon: DownloadOutlined,
    permissions: [
      "GET_TOTAL_POINT_MEMBER",
      "GET_INSTALLMENTS_RECAP_COUNT",
      "GET_ALL_SAVINGS_RECAP",
      "GET_SAVINGS_DIVIDEND_RECAP",
      "GET_SAVINGS_MONTHLY",
      "GET_SAVINGS_RECAP",
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "riwayat-pembayaran",
    path: `${ADMIN_PREFIX_PATH}/riwayat-pembayaran`,
    title: "Riwayat Pembayaran",
    icon: ReloadOutlined,
    permissions: [
      "ACCEPT_LOAN",
      "CHECK_USER_LOAN_CONFIRMATION",
      "CONFIRM_LOAN",
      "DELIVERY_TIME_CHECK",
      "GET_BAI_BARANG_LOAN",
      "GET_LOANS",
      "GET_RELEASED_TRANSCRIPT",
      "GET_BAI_BARANG_PO",
      "GET_BAI_BARANG_PO_DETAIL",
      "GET_LOANS_MONTHLY_BY_TYPE",
      "RELEASE_LOAN",
      "TRANSFER_LOAN",
    ],
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "master-plafond",
    path: `${ADMIN_PREFIX_PATH}/plafond`,
    title: "Plafond",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "approval-pinjaman",
    path: `${ADMIN_PREFIX_PATH}/approval-pinjaman`,
    title: "Approval Pinjaman",
    icon: CheckCircleOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pinjaman-barang",
    path: `${ADMIN_PREFIX_PATH}/pinjaman-barang`,
    title: "Pinjaman Barang",
    icon: CodeSandboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pinjaman-stnk",
    path: `${ADMIN_PREFIX_PATH}/pinjaman-stnk`,
    title: "Pinjaman STNK",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pelunasan",
    path: `${ADMIN_PREFIX_PATH}/pelunasan`,
    title: "Pelunasan",
    icon: CodeSandboxOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengaturan-budget",
    path: `${ADMIN_PREFIX_PATH}/pengaturan-budget`,
    title: "Pengaturan Budget",
    icon: SlidersOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const name = localStorage.getItem("name");

if (name) {
  if (name.search("Elkopra HQ") > 0) {
    dashBoardNavTree.push({
      key: "settlement",
      path: `${ADMIN_PREFIX_PATH}/settlement`,
      title: "Pelunasan Pinjaman",
      icon: DatabaseOutlined,
      breadcrumb: false,
      submenu: [],
    });
  }
}

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
